import { Button, Card } from "react-bootstrap";

export function CookieConsent({ setCookies }) {
  const giveCookieConsent = () => {
    setCookies("cookieConsent", true, { path: "/" });
  };

  const denyCookies = () => {
    setCookies("cookieConsent", false, { path: "/" });
  };

  return (
    <Card id="cookie-banner">
      <Card.Body>
        <Card.Text>
          Questo sito utilizza cookie tecnici. Cliccando su "Accetta", accetti
          l'utilizzo dei cookie descritto nella sezione{" "}
          <a href="/#/CookiePolicy" rel="noreferrer">
            Privacy & Cookie Policy
          </a>
        </Card.Text>
        <Button onClick={giveCookieConsent} variant="dark" className="me-3">
          Accetta
        </Button>
        <Button variant="outline-dark" onClick={denyCookies}>
          Rifiuta
        </Button>
      </Card.Body>
    </Card>
  );
}
