import React from "react";
import { Container } from "react-bootstrap";

export const CookiePolicy = () => {
  return (
    <Container className="p-4">
      <div>
        <h3>Cookie Policy di www.studiostoppa.com/</h3>
        <p>
          Questo documento contiene informazioni in merito alle tecnologie che
          consentono a questa Applicazione di raggiungere gli scopi descritti di
          seguito. Tali tecnologie permettono al Titolare di raccogliere e
          salvare informazioni (per esempio tramite l'utilizzo di Cookie) o di
          utilizzare risorse (per esempio eseguendo uno script) sul dispositivo
          dell'Utente quando quest'ultimo interagisce con questa Applicazione.
        </p>
        <p>
          Per semplicità, in questo documento tali tecnologie sono
          sinteticamente definite “Strumenti di Tracciamento”, salvo vi sia
          ragione di differenziare. Per esempio, sebbene i Cookie possano essere
          usati in browser sia web sia mobili, sarebbe fuori luogo parlare di
          Cookie nel contesto di applicazioni per dispositivi mobili, dal
          momento che si tratta di Strumenti di Tracciamento che richiedono la
          presenza di un browser. Per questo motivo, all'interno di questo
          documento il termine Cookie è utilizzato solo per indicare in modo
          specifico quel particolare tipo di Strumento di Tracciamento.
        </p>
        <p>
          Alcune delle finalità per le quali vengono impiegati Strumenti di
          Tracciamento potrebbero, inoltre richiedere il consenso dell'Utente.
          Se viene prestato il consenso, esso può essere revocato liberamente in
          qualsiasi momento seguendo le istruzioni contenute in questo documento
        </p>
        <p>
          Questa Applicazione utilizza solo Strumenti di Tracciamento gestiti
          direttamente dal Titolare (comunemente detti Strumenti di Tracciamento
          “di prima parte”). Durata e scadenza dei Cookie di prima parte e degli
          altri Strumenti di Tracciamento simili possono variare a seconda di
          quanto impostato dal Titolare. Alcuni di essi scadono al termine della
          sessione di navigazione dell'Utente.
        </p>
        <h3>
          Attività strettamente necessarie a garantire il funzionamento di
          questa Applicazione e la fornitura del Servizio
        </h3>
        <p>
          Questa Applicazione utilizza Cookie comunemente detti “tecnici” o
          altri Strumenti di Tracciamento analoghi per svolgere attività
          strettamente necessarie a garantire il funzionamento o la fornitura
          del Servizio.
        </p>
        <p className="fw-bold">
          Come gestire le preferenze e prestare o revocare il consenso
        </p>
        <p>
          Esistono vari modi per gestire le preferenze relative agli Strumenti
          di Tracciamento e per prestare o revocare il consenso, ove necessario:
        </p>
        <p>
          Gli Utenti possono gestire le preferenze relative agli Strumenti di
          Tracciamento direttamente tramite le impostazioni dei propri
          dispositivi - per esempio, possono impedire l'uso o l'archiviazione di
          Strumenti di Tracciamento.
        </p>
        <p>
          In aggiunta, ogni qualvolta l'utilizzo di Strumenti di Tracciamento
          dipenda da consenso, l'Utente può prestare o revocare tale consenso
          impostando le proprie preferenze all'interno dell'informativa sui
          cookie o aggiornando tali preferenze tramite il widget privacy per le
          preferenze relative al consenso, se presente.
        </p>
        <p>
          Grazie ad apposite funzioni del browser o del dispositivo è anche
          possibile rimuovere Strumenti di Tracciamento precedentemente salvati,
          inclusi quelli utilizzati per il salvataggio delle preferenze relative
          al consenso inizialmente espresse dall'Utente.
        </p>
        <p>
          Altri Strumenti di Tracciamento presenti nella memoria locale del
          browser possono essere rimossi cancellando la cronologia di
          navigazione.
        </p>
        <p className="fw-bold">
          Individuare le impostazioni relative agli Strumenti di Tracciamento
        </p>
        <p>
          Gli Utenti possono, per esempio, trovare informazioni su come gestire
          i Cookie in alcuni dei browser più diffusi ai seguenti indirizzi:
        </p>
        <ul>
          <li>
            ● &nbsp;
            <a
              href="https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies"
              target="_blank"
              rel="noreferrer"
            >
              Google Chrome
            </a>
          </li>
          <li>
            ● &nbsp;
            <a
              href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it"
              target="_blank"
              rel="noreferrer"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            ● &nbsp;
            <a
              href="https://support.apple.com/it-it/guide/safari/sfri11471/mac"
              target="_blank"
              rel="noreferrer"
            >
              Apple Safari
            </a>
          </li>
          <li>
            ● &nbsp;
            <a
              href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              target="_blank"
              rel="noreferrer"
            >
              Microsoft Internet Explorer
            </a>
          </li>
          <li>
            ● &nbsp;
            <a
              href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
              target="_blank"
              rel="noreferrer"
            >
              Microsoft Edge
            </a>
          </li>
          <li>
            ● &nbsp;
            <a
              href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing"
              target="_blank"
              rel="noreferrer"
            >
              Brave
            </a>
          </li>
          <li>
            ● &nbsp;
            <a
              href="https://help.opera.com/en/latest/web-preferences/#cookies"
              target="_blank"
              rel="noreferrer"
            >
              Opera
            </a>
          </li>
        </ul>
        <p>
          Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento
          per applicazioni mobili disattivandoli tramite le apposite
          impostazioni del dispositivo, quali le impostazioni di pubblicità per
          dispositivi mobili o le impostazioni relative al tracciamento in
          generale (gli Utenti possono consultare le impostazioni del
          dispositivo per individuare quella pertinente).
        </p>
        <p className="fw-bold">
          Conseguenze legate al rifiuto dell'utilizzo di Strumenti di
          Tracciamento
        </p>
        <p>
          Gli Utenti sono liberi di decidere se permettere o meno l'utilizzo di
          Strumenti di Tracciamento. Tuttavia, si noti che gli Strumenti di
          Tracciamento consentono a questa Applicazione di fornire agli Utenti
          un'esperienza migliore e funzionalità avanzate (in linea con le
          finalità delineate nel presente documento). Pertanto, qualora l'Utente
          decida di bloccare l'utilizzo di Strumenti di Tracciamento, il
          Titolare potrebbe non essere in grado di fornire le relative
          funzionalità.
        </p>
        <p className="fw-bold">Titolare del Trattamento dei Dati</p>
        <p>
          Studio Commercialista Stoppa - Via Groane, 2/A - Garbagnate Milanese
          (MI)
        </p>
        <p>Indirizzo email del Titolare: k.stoppa@studiostoppa.com</p>
        <p>
          Data l'oggettiva complessità di identificazione delle tecnologie di
          tracciamento, gli Utenti sono invitati a contattare il Titolare
          qualora volessero ricevere ulteriori informazioni in merito
          all'utilizzo di tali tecnologie su questa Applicazione.
        </p>
        <p className="fw-bold">Definizioni e riferimenti legali</p>
        <p>Dati Personali (o Dati)</p>
        <p>
          Costituisce dato personale qualunque informazione che, direttamente o
          indirettamente, anche in collegamento con qualsiasi altra
          informazione, ivi compreso un numero di identificazione personale,
          renda identificata o identificabile una persona fisica.
        </p>
        <p className="fw-bold">Dati di Utilizzo</p>
        <p>
          Sono le informazioni raccolte automaticamente attraverso questa
          Applicazione (anche da applicazioni di parti terze integrate in questa
          Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei
          computer utilizzati dall'Utente che si connette con questa
          Applicazione, gli indirizzi in notazione URI (Uniform Resource
          Identifier), l'orario della richiesta, il metodo utilizzato
          nell'inoltrare la richiesta al server, la dimensione del file ottenuto
          in risposta, il codice numerico indicante lo stato della risposta dal
          server (buon fine, errore, ecc.) il paese di provenienza, le
          caratteristiche del browser e del sistema operativo utilizzati dal
          visitatore, le varie connotazioni temporali della visita (ad esempio
          il tempo di permanenza su ciascuna pagina) e i dettagli relativi
          all'itinerario seguito all'interno dell'Applicazione, con particolare
          riferimento alla sequenza delle pagine consultate, ai parametri
          relativi al sistema operativo e all'ambiente informatico dell'Utente.
        </p>
        <p>Utente</p>
        <p>
          L'individuo che utilizza questa Applicazione che, salvo ove
          diversamente specificato, coincide con l'Interessato.
        </p>
        <p>Interessato</p>
        <p>La persona fisica cui si riferiscono i Dati Personali.</p>
        <p className="fw-bold">Responsabile del Trattamento (o Responsabile)</p>
        <p>
          La persona fisica, giuridica, la pubblica amministrazione e qualsiasi
          altro ente che tratta dati personali per conto del Titolare, secondo
          quanto esposto nella presente privacy policy.
        </p>
        <p className="fw-bold">Titolare del Trattamento (o Titolare)</p>
        <p>
          La persona fisica o giuridica, l'autorità pubblica, il servizio o
          altro organismo che, singolarmente o insieme ad altri, determina le
          finalità e i mezzi del trattamento di dati personali e gli strumenti
          adottati, ivi comprese le misure di sicurezza relative al
          funzionamento ed alla fruizione di questa Applicazione. Il Titolare
          del Trattamento, salvo quanto diversamente specificato, è il titolare
          di questa Applicazione.
        </p>
        <p className="fw-bold">Questa Applicazione</p>
        <p>
          Lo strumento hardware o software mediante il quale sono raccolti e
          trattati i Dati Personali degli Utenti.
        </p>
        <p className="fw-bold">Servizio</p>
        <p>
          Il Servizio fornito da questa Applicazione così come definito nei
          relativi termini (se presenti) su questo sito/applicazione.
        </p>
        <p className="fw-bold">Unione Europea (o UE)</p>
        <p>
          Salvo ove diversamente specificato, ogni riferimento all'Unione
          Europea contenuto in questo documento si intende esteso a tutti gli
          attuali stati membri dell'Unione Europea e dello Spazio Economico
          Europeo.
        </p>
        <p className="fw-bold">Cookie</p>
        <p>
          I Cookie sono Strumenti di Tracciamento che consistono in piccole
          porzioni di dati conservate all'interno del browser dell'Utente.
        </p>
        <p className="fw-bold">Strumento di Tracciamento</p>
        <p>
          Per Strumento di Tracciamento s'intende qualsiasi tecnologia - es.
          Cookie, identificativi univoci, web beacons, script integrati, etag e
          fingerprinting - che consenta di tracciare gli Utenti, per esempio
          raccogliendo o salvando informazioni sul dispositivo dell'Utente.
        </p>
        <br />
        <hr />

        <br />
        <h3>Privacy Policy di www.studiostoppa.com/</h3>
        <p>
          Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.
        </p>
        <p className="fw-bold">Titolare del Trattamento dei Dati</p>
        <p>
          Studio Commercialista Stoppa - Via Groane, 2/A - Garbagnate Milanese
          (MI)
        </p>
        <p>Indirizzo email del Titolare: k.stoppa@studiostoppa.com</p>
        <p>Tipologie di Dati raccolti</p>
        <p className="fw-bold"></p>
        <p>
          Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo
          o tramite terze parti, ci sono: nome; cognome; numero di telefono;
          Dati comunicati durante l'utilizzo del servizio; risposte alle
          domande; contenuti dell'utente; contenuti del messaggio o dell'email;
          immagine di profilo; data del messaggio; ora in cui il messaggio è
          stato inviato; mittente del messaggio.
        </p>
        <p>
          Dettagli completi su ciascuna tipologia di Dati Personali raccolti
          sono forniti nelle sezioni dedicate di questa privacy policy o
          mediante specifici testi informativi visualizzati prima della raccolta
          dei Dati stessi. I Dati Personali possono essere liberamente forniti
          dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente
          durante l'uso di questa Applicazione. Se non diversamente specificato,
          tutti i Dati richiesti da questa Applicazione sono obbligatori. Se
          l'Utente rifiuta di comunicarli, potrebbe essere impossibile per
          questa Applicazione fornire il Servizio. Nei casi in cui questa
          Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono
          liberi di astenersi dal comunicare tali Dati, senza che ciò abbia
          alcuna conseguenza sulla disponibilità del Servizio o sulla sua
          operatività. Gli Utenti che dovessero avere dubbi su quali Dati siano
          obbligatori sono incoraggiati a contattare il Titolare. L'eventuale
          utilizzo di Cookie - o di altri strumenti di tracciamento - da parte
          di questa Applicazione o dei titolari dei servizi terzi utilizzati da
          questa Applicazione ha la finalità di fornire il Servizio richiesto
          dall'Utente, oltre alle ulteriori finalità descritte nel presente
          documento e nella Cookie Policy.
        </p>
        <p>
          L'Utente si assume la responsabilità dei Dati Personali di terzi
          ottenuti, pubblicati o condivisi mediante questa Applicazione.
        </p>
        <p className="fw-bold">
          Modalità e luogo del trattamento dei Dati raccolti
        </p>
        <p>Modalità di trattamento:</p>
        <p>
          Il Titolare adotta le opportune misure di sicurezza volte ad impedire
          l'accesso, la divulgazione, la modifica o la distruzione non
          autorizzate dei Dati Personali. Il trattamento viene effettuato
          mediante strumenti informatici e/o telematici, con modalità
          organizzative e con logiche strettamente correlate alle finalità
          indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso
          ai Dati altri soggetti coinvolti nell'organizzazione di questa
          Applicazione (personale amministrativo, commerciale, marketing,
          legali, amministratori di sistema) ovvero soggetti esterni (come
          fornitori di servizi tecnici terzi, corrieri postali, hosting
          provider, società informatiche, agenzie di comunicazione) nominati
          anche, se necessario, Responsabili del Trattamento da parte del
          Titolare. L'elenco aggiornato dei Responsabili potrà sempre essere
          richiesto al Titolare del Trattamento.
        </p>
        <p className="fw-bold">Luogo</p>
        <p>
          I Dati sono trattati presso le sedi operative del Titolare ed in ogni
          altro luogo in cui le parti coinvolte nel trattamento siano
          localizzate. Per ulteriori informazioni, contatta il Titolare. I Dati
          Personali dell'Utente potrebbero essere trasferiti in un paese diverso
          da quello in cui l'Utente si trova. Per ottenere ulteriori
          informazioni sul luogo del trattamento l'Utente può fare riferimento
          alla sezione relativa ai dettagli sul trattamento dei Dati Personali.
        </p>
        <p className="fw-bold">Periodo di conservazione</p>
        <p>
          Se non diversamente indicato in questo documento, i Dati Personali
          sono trattati e conservati per il tempo richiesto dalla finalità per
          la quale sono stati raccolti e potrebbero essere conservati per un
          periodo più lungo a causa di eventuali obbligazioni legali o sulla
          base del consenso degli Utenti.
        </p>
        <p className="fw-bold">Finalità del Trattamento dei Dati raccolti</p>
        <p>
          I Dati dell'Utente sono raccolti per consentire al Titolare di fornire
          il Servizio, adempiere agli obblighi di legge, rispondere a richieste
          o azioni esecutive, tutelare i propri diritti ed interessi (o quelli
          di Utenti o di terze parti), individuare eventuali attività dolose o
          fraudolente, nonché per le seguenti finalità: Interazione con le
          piattaforme di live chat. Per ottenere informazioni dettagliate sulle
          finalità del trattamento e sui Dati Personali trattati per ciascuna
          finalità, l'Utente può fare riferimento alla sezione “Dettagli sul
          trattamento dei Dati Personali”.
        </p>
        <p className="fw-bold">Dettagli sul trattamento dei Dati Personali</p>
        <p>
          I Dati Personali sono raccolti per le seguenti finalità ed utilizzando
          i seguenti servizi:
        </p>
        <p className="fw-bold">Interazione con le piattaforme di live chat</p>
        <p>
          Questo tipo di servizi permette di interagire con le piattaforme di
          live chat gestite da soggetti terzi, direttamente dalle pagine di
          questa Applicazione, al fine di poter contattare ed essere contattati
          dal servizio di supporto di questa Applicazione. Nel caso in cui sia
          installato un servizio di interazione con le piattaforme di live chat,
          è possibile che, anche nel caso gli Utenti non utilizzino il servizio,
          lo stesso raccolga Dati di Utilizzo relativi alle pagine in cui è
          installato. Inoltre, le conversazioni della live chat potrebbero
          essere registrate.
        </p>
        <p className="fw-bold">
          WhatsApp Business Chat widget (Meta Platforms Ireland Limited)
        </p>
        <p>
          Il WhatsApp Business Chat widget è un servizio di interazione con la
          piattaforma di live chat WhatsApp, fornito da Meta Platforms Ireland
          Limited. Dati Personali trattati: cognome; contenuti del messaggio o
          dell'email; contenuti dell'utente; data del messaggio; Dati comunicati
          durante l'utilizzo del servizio; immagine di profilo; mittente del
          messaggio; nome; numero di telefono; ora in cui il messaggio è stato
          inviato; risposte alle domande. Luogo del trattamento: Irlanda -{" "}
          <a
            href="https://www.whatsapp.com/legal/privacy-policy-eea"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
        <h3>Ulteriori informazioni per gli utenti</h3>
        <p className="fw-bold">Base giuridica del trattamento</p>
        <p>
          Il Titolare tratta Dati Personali relativi allUtente in caso sussista
          una delle seguenti condizioni:
        </p>
        <ul>
          <li>
            ● lUtente ha prestato il consenso per una o più finalità specifiche;
            Nota: in alcuni ordinamenti il Titolare può essere autorizzato a
            trattare Dati Personali senza che debba sussistere il consenso
            dellUtente o unaltra delle basi giuridiche specificate di seguito,
            fino a quando lUtente non si opponga (“opt-out”) a tale trattamento.
            Ciò non è tuttavia applicabile qualora il trattamento di Dati
            Personali sia regolato dalla legislazione europea in materia di
            protezione dei Dati Personali;
          </li>
          <li>
            ● il trattamento è necessario all'esecuzione di un contratto con
            lUtente e/o all'esecuzione di misure precontrattuali;
          </li>
          <li>
            ● il trattamento è necessario per adempiere un obbligo legale al
            quale è soggetto il Titolare;
          </li>
          <li>
            ● il trattamento è necessario per l'esecuzione di un compito di
            interesse pubblico o per l'esercizio di pubblici poteri di cui è
            investito il Titolare;
          </li>
          <li>
            ● il trattamento è necessario per il perseguimento del legittimo
            interesse del Titolare o di terzi.
          </li>
        </ul>
        <p>
          È comunque sempre possibile richiedere al Titolare di chiarire la
          concreta base giuridica di ciascun trattamento ed in particolare di
          specificare se il trattamento sia basato sulla legge, previsto da un
          contratto o necessario per concludere un contratto.
        </p>
        <p className="fw-bold">
          Ulteriori informazioni sul tempo di conservazione
        </p>
        <p>
          Se non diversamente indicato in questo documento, i Dati Personali
          sono trattati e conservati per il tempo richiesto dalla finalità per
          la quale sono stati raccolti e potrebbero essere conservati per un
          periodo più lungo a causa di eventuali obbligazioni legali o sulla
          base del consenso degli Utenti.
        </p>
        <p>Pertanto:</p>
        <ul>
          <li>
            ● I Dati Personali raccolti per scopi collegati all'esecuzione di un
            contratto tra il Titolare e l'Utente saranno trattenuti sino a
            quando sia completata l'esecuzione di tale contratto.
          </li>
          <li>
            ● I Dati Personali raccolti per finalità riconducibili all'interesse
            legittimo del Titolare saranno trattenuti sino al soddisfacimento di
            tale interesse. L'Utente può ottenere ulteriori informazioni in
            merito all'interesse legittimo perseguito dal Titolare nelle
            relative sezioni di questo documento o contattando il Titolare.
          </li>
        </ul>
        <p>
          Quando il trattamento è basato sul consenso dell'Utente, il Titolare
          può conservare i Dati Personali più a lungo sino a quando detto
          consenso non venga revocato. Inoltre, il Titolare potrebbe essere
          obbligato a conservare i Dati Personali per un periodo più lungo in
          ottemperanza ad un obbligo di legge o per ordine di un'autorità.
        </p>
        <p>
          Al termine del periodo di conservazione i Dati Personali saranno
          cancellati. Pertanto, allo spirare di tale termine il diritto di
          accesso, cancellazione, rettificazione ed il diritto alla portabilità
          dei Dati non potranno più essere esercitati.
        </p>
        <p className="fw-bold">Diritti dell'Utente</p>
        <p>
          Gli Utenti possono esercitare determinati diritti con riferimento ai
          Dati trattati dal Titolare.
        </p>
        <p>
          In particolare, nei limiti previsti dalla legge, l'Utente ha il
          diritto di:
        </p>
        <ul>
          <li>
            ● revocare il consenso in ogni momento. L'Utente può revocare il
            consenso al trattamento dei propri Dati Personali precedentemente
            espresso.
          </li>
          <li>
            ● opporsi al trattamento dei propri Dati. L'Utente può opporsi al
            trattamento dei propri Dati quando esso avviene in virtù di una base
            giuridica diversa dal consenso
          </li>
          <li>
            ● accedere ai propri Dati. L'Utente ha diritto ad ottenere
            informazioni sui Dati trattati dal Titolare, su determinati aspetti
            del trattamento ed a ricevere una copia dei Dati trattati.
          </li>
          <li>
            ● verificare e chiedere la rettificazione. L'Utente può verificare
            la correttezza dei propri Dati e richiederne l'aggiornamento o la
            correzione.
          </li>
          <li>
            ● ottenere la limitazione del trattamento. L'Utente può richiedere
            la limitazione del trattamento dei propri Dati. In tal caso il
            Titolare non tratterà i Dati per alcun altro scopo se non la loro
            conservazione.
          </li>
          <li>
            ● ottenere la cancellazione o rimozione dei propri Dati Personali.
            L'Utente può richiedere la cancellazione dei propri Dati da parte
            del Titolare.
          </li>
          <li>
            ● ricevere i propri Dati o farli trasferire ad altro titolare.
            L'Utente ha diritto di ricevere i propri Dati in formato
            strutturato, di uso comune e leggibile da dispositivo automatico e,
            ove tecnicamente fattibile, di ottenerne il trasferimento senza
            ostacoli ad un altro titolare
          </li>
          <li>
            ● proporre reclamo. L'Utente può proporre un reclamo all'autorità di
            controllo della protezione dei dati personali competente o agire in
            sede giudiziale.
          </li>
        </ul>
        <p>
          Gli Utenti hanno diritto di ottenere informazioni in merito alla base
          giuridica per il trasferimento di Dati all'estero incluso verso
          qualsiasi organizzazione internazionale regolata dal diritto
          internazionale o costituita da due o più paesi, come ad esempio l'ONU,
          nonché in merito alle misure di sicurezza adottate dal Titolare per
          proteggere i loro Dati.
        </p>
        <p className="fw-bold">Dettagli sul diritto di opposizione</p>
        <p className="fw-bold">
          Quando i Dati Personali sono trattati nell'interesse pubblico,
          nell'esercizio di pubblici poteri di cui è investito il Titolare
          oppure per perseguire un interesse legittimo del Titolare, gli Utenti
          hanno diritto ad opporsi al trattamento per motivi connessi alla loro
          situazione particolare.
        </p>
        <p className="fw-bold">
          Si fa presente agli Utenti che, ove i loro Dati fossero trattati con
          finalità di marketing diretto, possono opporsi al trattamento in
          qualsiasi momento, gratuitamente e senza fornire alcuna motivazione.
          Qualora gli Utenti si oppongano al trattamento per finalità di
          marketing diretto, i Dati Personali non sono più oggetto di
          trattamento per tali finalità. Per scoprire se il Titolare tratti Dati
          con finalità di marketing diretto gli Utenti possono fare riferimento
          alle rispettive sezioni di questo documento.
        </p>
        <p className="fw-bold">Come esercitare i diritti</p>
        <p>
          Per esercitare i propri diritti, gli Utenti possono indirizzare una
          richiesta ai recapiti del Titolare indicati in questo documento. La
          richiesta è gratuita e il Titolare risponderà nel più breve tempo
          possibile, in ogni caso entro un mese, fornendo all'Utente tutte le
          informazioni previste dalla legge. Eventuali rettifiche, cancellazioni
          o limitazioni del trattamento saranno comunicate dal Titolare a
          ciascuno dei destinatari, se esistenti, a cui sono stati trasmessi i
          Dati Personali, salvo che ciò si riveli impossibile o implichi uno
          sforzo sproporzionato. Il Titolare comunica all'Utente tali
          destinatari qualora egli lo richieda.
        </p>
        <p className="fw-bold">Ulteriori informazioni sul trattamento</p>
        <p className="fw-bold">Difesa in giudizio</p>
        <p>
          I Dati Personali dell'Utente possono essere utilizzati da parte del
          Titolare in giudizio o nelle fasi preparatorie alla sua eventuale
          instaurazione per la difesa da abusi nell'utilizzo di questa
          Applicazione o dei Servizi connessi da parte dell'Utente. L'Utente
          dichiara di essere consapevole che il Titolare potrebbe essere
          obbligato a rivelare i Dati per ordine delle autorità pubbliche.
        </p>
        <p className="fw-bold">Informative specifiche</p>
        <p>
          Su richiesta dell'Utente, in aggiunta alle informazioni contenute in
          questa privacy policy, questa Applicazione potrebbe fornire all'Utente
          delle informative aggiuntive e contestuali riguardanti Servizi
          specifici, o la raccolta ed il trattamento di Dati Personali.
        </p>
        <p className="fw-bold">Log di sistema e manutenzione</p>
        <p>
          Per necessità legate al funzionamento ed alla manutenzione, questa
          Applicazione e gli eventuali servizi terzi da essa utilizzati
          potrebbero raccogliere log di sistema, ossia file che registrano le
          interazioni e che possono contenere anche Dati Personali, quali
          l'indirizzo IP Utente.
        </p>
        <p className="fw-bold">Informazioni non contenute in questa policy</p>
        <p>
          Ulteriori informazioni in relazione al trattamento dei Dati Personali
          potranno essere richieste in qualsiasi momento al Titolare del
          Trattamento utilizzando gli estremi di contatto.
        </p>
        <p>Modifiche a questa privacy policy</p>
        <p>
          Il Titolare del Trattamento si riserva il diritto di apportare
          modifiche alla presente privacy policy in qualunque momento
          notificandolo agli Utenti su questa pagina e, se possibile, su questa
          Applicazione nonché, qualora tecnicamente e legalmente fattibile,
          inviando una notifica agli Utenti attraverso uno degli estremi di
          contatto di cui è in possesso. Si prega dunque di consultare con
          frequenza questa pagina, facendo riferimento alla data di ultima
          modifica indicata in fondo.
        </p>
        <p>
          Qualora le modifiche interessino trattamenti la cui base giuridica è
          il consenso, il Titolare provvederà a raccogliere nuovamente il
          consenso dell'Utente, se necessario
        </p>
        <p className="fw-bold">Definizioni e riferimenti legali</p>
        <p className="fw-bold">Dati Personali (o Dati)</p>
        <p>
          Costituisce dato personale qualunque informazione che, direttamente o
          indirettamente, anche in collegamento con qualsiasi altra
          informazione, ivi compreso un numero di identificazione personale,
          renda identificata o identificabile una persona fisica.
        </p>
        <p className="fw-bold">Dati di Utilizzo</p>
        <p>
          Sono le informazioni raccolte automaticamente attraverso questa
          Applicazione (anche da applicazioni di parti terze integrate in questa
          Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei
          computer utilizzati dall'Utente che si connette con questa
          Applicazione, gli indirizzi in notazione URI (Uniform Resource
          Identifier), l'orario della richiesta, il metodo utilizzato
          nell'inoltrare la richiesta al server, la dimensione del file ottenuto
          in risposta, il codice numerico indicante lo stato della risposta dal
          server (buon fine, errore, ecc.) il paese di provenienza, le
          caratteristiche del browser e del sistema operativo utilizzati dal
          visitatore, le varie connotazioni temporali della visita (ad esempio
          il tempo di permanenza su ciascuna pagina) e i dettagli relativi
          all'itinerario seguito all'interno dell'Applicazione, con particolare
          riferimento alla sequenza delle pagine consultate, ai parametri
          relativi al sistema operativo e all'ambiente informatico dell'Utente.
        </p>
        <p className="fw-bold">Utente</p>
        <p>
          L'individuo che utilizza questa Applicazione che, salvo ove
          diversamente specificato, coincide con l'Interessato.
        </p>
        <p className="fw-bold">Interessato</p>
        <p>La persona fisica cui si riferiscono i Dati Personali.</p>
        <p className="fw-bold">Responsabile del Trattamento (o Responsabile)</p>
        <p>
          La persona fisica, giuridica, la pubblica amministrazione e qualsiasi
          altro ente che tratta dati personali per conto del Titolare, secondo
          quanto esposto nella presente privacy policy.
        </p>
        <p className="fw-bold">Titolare del Trattamento (o Titolare)</p>
        <p>
          La persona fisica o giuridica, l'autorità pubblica, il servizio o
          altro organismo che, singolarmente o insieme ad altri, determina le
          finalità e i mezzi del trattamento di dati personali e gli strumenti
          adottati, ivi comprese le misure di sicurezza relative al
          funzionamento ed alla fruizione di questa Applicazione. Il Titolare
          del Trattamento, salvo quanto diversamente specificato, è il titolare
          di questa Applicazione.
        </p>
        <p className="fw-bold">Questa Applicazione</p>
        <p>
          Lo strumento hardware o software mediante il quale sono raccolti e
          trattati i Dati Personali degli Utenti.
        </p>
        <p className="fw-bold">Servizio</p>
        <p>
          Il Servizio fornito da questa Applicazione così come definito nei
          relativi termini (se presenti) su questo sito/applicazione.
        </p>
        <p className="fw-bold">Unione Europea (o UE)</p>
        <p>
          Salvo ove diversamente specificato, ogni riferimento all'Unione
          Europea contenuto in questo documento si intende esteso a tutti gli
          attuali stati membri dell'Unione Europea e dello Spazio Economico
          Europeo.
        </p>
        <p className="fw-bold">Riferimenti legali</p>
        <p>
          Ove non diversamente specificato, questa informativa privacy riguarda
          esclusivamente questa Applicazione.
        </p>
        <p>Ultima modifica: 21 febbraio 2024</p>
      </div>
    </Container>
  );
};
