import React from "react";
import { Col, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export const MyNavbar = () => {
  const location = useLocation();

  return (
    <>
      <Navbar bg="dark" className="text-light" id="fixed">
        <Container>
          <Col>
            <a
              href="tel:+393395383483"
              target="_blank"
              rel="noreferrer"
              className="text-light"
            >
              <i className="bi bi-telephone-fill me-2"></i>3395383483
            </a>
          </Col>
          <Col className="text-end">
            <a
              href="https://wa.me/+393517633947"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-whatsapp text-light"></i>
            </a>
            <a
              href="mailto:info@studiostoppa.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-envelope-fill mx-3 text-light "></i>
            </a>
            <a
              href="https://www.facebook.com/studiostoppa"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-facebook text-light me-4"></i>
            </a>
          </Col>
        </Container>
      </Navbar>
      <Navbar collapseOnSelect expand="xl" className="py-3" id="myBanner">
        <Container>
          <Navbar.Brand className="text-light">
            <Link className="nav-link" id="navLink" to="/">
              <img
                src="/img/logoODC.png"
                style={{ width: "50px", height: "50px" }}
                alt="Log"
                className="me-3"
              />
              <span className="fs-3">Studio Stoppa</span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar-expand-lg" />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="end"
            style={{
              backgroundColor: "rgba(220, 53, 70, 0.8)",
              maxWidth: "40vw",
              color: "white",
            }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link
                  as={Link}
                  id={"navLink" + (location.pathname === "/" ? "Active" : "")}
                  to="/"
                  href="/"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  id={
                    "navLink" +
                    (location.pathname === "/ChiSiamo" ? "Active" : "")
                  }
                  to="/ChiSiamo"
                  href="/"
                >
                  Chi Siamo
                </Nav.Link>
                <Nav.Link
                  id="navLink"
                  href="https://www.dols.it/2011/12/13/professione-commercialista/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cosa dicono di noi
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  id={
                    "navLink" +
                    (location.pathname === "/Servizi" ? "Active" : "")
                  }
                  to="/Servizi"
                  href="/"
                >
                  Servizi
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  id={
                    "navLink" +
                    (location.pathname === "/Contatti" ? "Active" : "")
                  }
                  to="/Contatti"
                  href="/"
                >
                  Contatti
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  id={
                    "navLink" +
                    (location.pathname === "/Circolari" ? "Active" : "")
                  }
                  to="/Circolari"
                  href="/"
                >
                  Circolari
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  id={
                    "navLink" +
                    (location.pathname === "/LinkUtili" ? "Active" : "")
                  }
                  to="/LinkUtili"
                  href="/"
                >
                  Link Utili
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};
