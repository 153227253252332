import React from "react";
import { Container, ListGroup } from "react-bootstrap";
import json from "../data/links.json";

export const LinkUtili = () => {
    return (
        <Container>
            <ListGroup className="text-center my-4">
                {json.map((link, i) => (
                    <ListGroup.Item variant={i % 2 === 0 ? "dark" : "secondary"} key={i}>
                        {link.text}{" "}
                        <a href={link.URL} target="_blank" rel="noreferrer" className="text-danger">
                            {link.link}
                        </a>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Container>
    );
};
