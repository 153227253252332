import React from "react";
import { Container, ListGroup } from "react-bootstrap";
import json from "../data/circolari.json";

export const Circolari = () => {
    return (
        <Container>
            <ListGroup className=" my-4">
                {json.map((circolare, i) => (
                    <ListGroup.Item
                        variant={circolare.variant}
                        key={i}
                        className="d-flex px-4 align-items-center"
                    >
                        {circolare.text}{" "}
                        <a
                            href={circolare.file}
                            target="_blank"
                            rel="noreferrer"
                            className="text-danger ps-2 ms-auto"
                        >
                            {circolare.file !== "" && (
                                <i className="bi bi-file-earmark-arrow-down-fill fs-3"></i>
                            )}
                        </a>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Container>
    );
};
