import React from "react";
import { Container } from "react-bootstrap";

export const Servizi = () => {
    return (
        <>
            <div id="service">
                <div id="redDiv">
                    <h2 className="fw-bold mb-4">I NOSTRI SERVIZI:</h2>
                    <p>
                        Il nostro studio offre servizi di elaborazione dati contabili per imprese,
                        società e professionisti, nonchè consulenze amministrative (anche in
                        outsourcing), dichiarazioni dei redditi, consulenze fiscali, societarie ed
                        aziendali.
                    </p>
                </div>
            </div>
            <Container className="p-5">
                <h3 className="text-danger">ELABORAZIONE DATI:</h3>
                <ul id="myUl">
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i>
                        Elaborazione dati contabili per ogni tipo di contribuente, con redazione
                        periodica di bilanci di verifica ed invii telematici relativi.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Consulenza contabile e
                        amministrativa in outsourcing, ove necessario.
                    </li>
                </ul>
                <h3 className="text-danger">DICHIARAZIONE DEI REDDITI:</h3>
                <ul id="myUl">
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> consulenza per redazione ed
                        invio telematico degli stessi.
                    </li>
                </ul>
                <h3 className="text-danger">CONSULENZE FISCALI, SOCIETARIE E AZIENDALI:</h3>
                <ul id="myUl">
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Assistenza continuativa
                        fiscale e societaria.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Consulenza per contribuenti
                        minimi e forfettari.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Pianificazione fiscale.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Consulenza sugli studi di
                        settore.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Consulenza e assistenza
                        societaria e persone fisiche.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Gestione della contabilita'
                        e dell'amministrazione in occasione di start-up aziendali.
                    </li>
                </ul>
                <h3 className="text-danger">SERVIZI AGGIUNTIVI:</h3>
                <ul id="myUl">
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Gestione, redazione e
                        registrazione contratti di locazione.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Pratiche della Camera di
                        Commercio.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Consulenza e assistenza GDPR
                        (privacy).
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Gestione di patrimoni
                        aziendali e personali.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Riorganizzazioni aziendali.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Mediazione civile
                        professionale.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i> Denunce di successione.
                    </li>
                    <li>
                        <i className="bi bi-caret-right-fill me-2"></i>
                        Presenza in studio di consulente del lavoro, avvocato e realizzatore di siti
                        Web.
                    </li>
                </ul>
            </Container>
        </>
    );
};
