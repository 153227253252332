import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const ChiSiamo = () => {
    return (
        <Container>
            <Row xs={1} md={2} className="my-3" id="mobileDirection">
                <Col>
                    <h3 className="text-danger mb-3">Rag. Ketty Stoppa</h3>
                    <h5>Studi:</h5>
                    <ul id="myUl">
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i>
                            Diploma di Ragioniere e Perito Commerciale dal 1986.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Abilitazione
                            all’esercizio della professione di commercialista conseguita a Milano
                            nel 1989.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Albo Dottori
                            Commercialisti ed Esperti Contabili (ex Collegio Ragionieri) dal 1990.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Albo Revisori dal 1999.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Albo Mediatori civili
                            dal 2013.
                        </li>
                    </ul>
                    <h5>Aree di competenza:</h5>
                    <ul id="myUl">
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i>
                            Esperta in problematiche fiscali.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Controller.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Contenzioso tributario.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Diritto societario.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Consulenza aziendale,
                            contabile e amministrativa in outsourcing.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Start up aziendali.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Esperta normativa
                            contratti locazione.
                        </li>
                    </ul>
                </Col>
                <Col className="d-flex align-items-center my-3">
                    <img src="./img/ketty.jpg" alt="ketty" width="100%" className="rounded" />
                </Col>
            </Row>
            <Row xs={1} md={2} className="mb-4">
                <Col className="d-flex align-items-center">
                    <img src="./img/denise.jpg" alt="denise" width="100%" className="rounded" />
                </Col>
                <Col className="ps-4">
                    <h3 className="text-danger my-3">Rag. Denise Ascione</h3>
                    <h5>Studi:</h5>
                    <ul id="myUl">
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i>
                            Diploma di Ragioniere e Perito Commerciale dal 2013.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Master antiriciclaggio.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Master GDPR.
                        </li>
                    </ul>
                    <h5>Aree di competenza:</h5>
                    <ul id="myUl">
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i>
                            Elaborazione dati.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Antiriciclaggio.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> GDPR.
                        </li>
                        <li>
                            <i className="bi bi-caret-right-fill me-2"></i> Fatturazione
                            elettronica.
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};
