import React from "react";
import { Col, Row } from "react-bootstrap";

export const Contatti = () => {
    return (
        <>
            <div id="contact">
                <div id="redDiv">
                    <h2 className="fw-bold mb-4">CONTATTACI:</h2>
                    <Row xs={1} md={2}>
                        <Col>
                            <p>
                                <a
                                    href="tel:+393395383483"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-light"
                                >
                                    <i className="bi bi-phone-fill me-2"></i>Cell: 3395383483
                                </a>
                            </p>
                            <p>
                                <a
                                    href="https://wa.me/+393517633947"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-light"
                                >
                                    <i className="bi bi-whatsapp text-light me-2"></i>
                                    Wa: 3517633947
                                </a>
                            </p>
                            <p>
                                <a
                                    href="tel:+390299020157"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-light"
                                >
                                    <i className="bi bi-telephone-fill me-2"></i>Tel. 0299020157
                                </a>
                            </p>
                            <p>
                                <i className="bi bi-printer-fill me-2"></i>Fax 0283521200
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <a
                                    href="mailto:info@studiostoppa.com"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-light"
                                >
                                    <i className="bi bi-envelope-fill me-2"></i>
                                    info@studiostoppa.com
                                </a>
                            </p>
                            <p>
                                <a
                                    href="mailto:k.stoppa@studiostoppa.com"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-light"
                                >
                                    <i className="bi bi-envelope-fill me-2"></i>
                                    k.stoppa@studiostoppa.com
                                </a>
                            </p>
                            <p>
                                <a
                                    href="mailto:d.ascione@studiostoppa.com"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-light"
                                >
                                    <i className="bi bi-envelope-fill me-2"></i>
                                    d.ascione@studiostoppa.com
                                </a>
                            </p>
                            <p>
                                <a
                                    href="mailto:ketty.stoppa@odcecmilano.it"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-light"
                                >
                                    <i className="bi bi-envelope-check-fill me-2"></i>
                                    ketty.stoppa@odcecmilano.it
                                </a>
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <iframe
                    title="Mappa"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=studio%20stoppa%20garbagnate&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                ></iframe>
            </div>
        </>
    );
};
