import React from "react";
import { Navbar } from "react-bootstrap";

export const MyFooter = () => {
  return (
    <Navbar className="text-light" id="myBanner">
      <div className="mx-auto">
        <div className="text-center">
          &copy; 2022 Studio Commercialista Stoppa | Via Groane, 2/A -
          Garbagnate Milanese (MI) | P.IVA 09578130157{" "}
        </div>
        <div className="text-center">
          Made By:{" "}
          <a
            href="https://danieleiudica.github.io/MyPortfolio/portfolio/"
            className="text-dark"
            target="_blank"
            rel="noreferrer"
          >
            Daniele Iudica
          </a>
        </div>
      </div>
    </Navbar>
  );
};
