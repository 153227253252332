import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { MyNavbar } from "./components/MyNavbar";
import { MyFooter } from "./components/MyFooter";
import { Home } from "./components/Home";
import { ChiSiamo } from "./components/ChiSiamo";
import { Contatti } from "./components/Contatti";
import { Servizi } from "./components/Servizi";
import { Circolari } from "./components/Circolari";
import { LinkUtili } from "./components/LinkUtili";
import { CookiePolicy } from "./components/CookiePolicy";

function App() {
  return (
    <HashRouter>
      <MyNavbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ChiSiamo" element={<ChiSiamo />} />
        <Route path="/Contatti" element={<Contatti />} />
        <Route path="/Servizi" element={<Servizi />} />
        <Route path="/Circolari" element={<Circolari />} />
        <Route path="/LinkUtili" element={<LinkUtili />} />
        <Route path="/CookiePolicy" element={<CookiePolicy />} />
        <Route path="*" element={<Home />} />
      </Routes>

      <MyFooter />
    </HashRouter>
  );
}

export default App;
