import React from "react";
import { Button, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CookieConsent } from "./cookieConsent";
import { useCookies } from "react-cookie";

export const Home = () => {
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies(["cookieConsent"]);

  return (
    <div className="text-center">
      <Carousel id="myCarousel">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./img/carousel/car1.jpg"
            alt="First slide"
            id="carouselImg"
          />
          <Carousel.Caption id="carouselCap">
            <div id="redDiv">
              <h2>Professionisti del Settore</h2>
              <Button
                variant="outline-light"
                size="lg"
                id="carBtn"
                onClick={() => {
                  navigate("/ChiSiamo");
                }}
              >
                Chi Siamo
              </Button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./img/carousel/car2.jpg"
            alt="Second slide"
            id="carouselImg"
          />
          <Carousel.Caption id="carouselCap">
            <div id="redDiv">
              <h2>Consulenze fiscali, societarie e aziendali</h2>
              <Button
                variant="outline-light"
                size="lg"
                id="carBtn"
                onClick={() => {
                  navigate("/Servizi");
                }}
              >
                I Nostri Servizi
              </Button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./img/carousel/car3.jpg"
            alt="Third slide"
            id="carouselImg"
          />

          <Carousel.Caption id="carouselCap">
            <div id="redDiv">
              <h2>Hai bisogno di assistenza?</h2>
              <Button
                variant="outline-light"
                size="lg"
                id="carBtn"
                onClick={() => {
                  navigate("/Contatti");
                }}
              >
                Contattaci
              </Button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./img/carousel/car5.jpg"
            alt="Third slide"
            id="carouselImg"
          />

          <Carousel.Caption id="carouselCap">
            <div id="redDiv">
              <h2>Resta aggiornato</h2>
              <Button
                variant="outline-light"
                size="lg"
                id="carBtn"
                onClick={() => {
                  navigate("/Circolari");
                }}
              >
                Servizio Circolari
              </Button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <img src="./img/homeBanner.png" alt="ordine_commercialisti" id="odcImg" />
      <div className="w-75 mx-auto my-4 fs-4">
        <p>
          Lo studio Commercialista Stoppa opera sul territorio lombardo dal{" "}
          <strong>1990</strong> nel settore della{" "}
          <strong>consulenza fiscale</strong>, <strong>contabile</strong> ed
          <strong> amministrativa</strong>.
        </p>
        <p>
          Dapprima a Milano e poi a <strong>Garbagnate Milanese</strong>, lo
          Studio gestisce gli affari dei propri clienti, con{" "}
          <strong>professionalità</strong> e assicurando sempre un rapporto
          fiduciario, personale ed attento alle esigenze sempre nuove degli
          stessi.
        </p>
        <p>
          La finalità è sempre il <strong>soddisfacimento del cliente</strong> a
          360°, anche operando
          <strong> strategie fiscali</strong>, nel rispetto delle normative in
          essere.
        </p>
        <p>
          La nostra <strong>regola</strong> è di non fare distinzione tra
          clienti di grandi dimensioni e piccole o medie imprese o
          professonisti, e di dedicare a tutti la stessa
          <strong> attenzione</strong> e il{" "}
          <strong>massimo delle nostre competenze</strong>.
        </p>
      </div>
      {!cookies.hasOwnProperty("cookieConsent") && (
        <CookieConsent setCookies={setCookies} />
      )}
    </div>
  );
};
